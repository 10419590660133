import { makeAutoObservable } from 'mobx';

class ConfirmModal {
    isOpenConfirmModal = false;
    content = '';
    title = '';
    callback = async () => {};
    constructor() {
        makeAutoObservable(this);
    }
    toggleConfirmModal() {
        this.isOpenConfirmModal = !this.isOpenConfirmModal;
    }
    confirm(callback, content, title) {
        if (!callback) {
            throw new Error('confirm callback is required');
        }
        this.callback = callback;
        this.toggleConfirmModal();
        this.content = content || '请确认是否继续操作';
        this.title = title || '提示';
    }
    async onSubmit() {
        const result = await this.callback(true);
        if (result === false) return;
        this.toggleConfirmModal();
    }
    async onClose() {
        const result = await this.callback(false);
        if (result === false) return;
        this.toggleConfirmModal();
    }
}
export const confirmModalStore = new ConfirmModal();

export const confirm = confirmModalStore.confirm.bind(confirmModalStore);
export const confirmPromise = (content, title) => {
    return new Promise(resolve => {
        confirmModalStore.confirm(resolve, content, title);
    });
};
